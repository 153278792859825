import React from "react";
import _ from "lodash";
import moment from "moment/moment";
import {CustomEditDatePicker, CustomFilterDatePicker} from "../../../components/Tables/Params/CustomDatePicker";
import { isEmailValid } from "@utils/validations";

export const getColumns = (intl, roles, almagamma_roles, users) => [
  {
    title: "E-mail",
    field: "email",
    defaultSort: "asc",
    validate: rowData => {
      return isEmailValid(rowData.email) || intl.formatMessage({ id: "enter_email" });
    },
  },
  {
    title: intl.formatMessage({ id: "name" }),
    field: "full_name",
    render: ({ full_name }) => <b>{full_name}</b>,
    headerStyle: {fontWeight: "bold", whiteSpace: "nowrap", width: 200, minWidth: 200},
    cellStyle: {width: 200, minWidth: 200},
  },
  {
    title: intl.formatMessage({ id: "role" }),
    field: "role_id",
    lookup: roles ? roles.reduce((obj, i) => _.set(obj, [i['id']], i['name'][0].toUpperCase() + i['name'].slice(1)), {}) : {},
  },
  {
    title: intl.formatMessage({ id: "role" }) + " Ammagamma",
    field: "almagamma_role.id_role",
    headerStyle: {fontWeight: "bold", whiteSpace: "nowrap", width: 250, minWidth: 250},
    cellStyle: {width: 250, minWidth: 250},
    lookup: almagamma_roles ? almagamma_roles.reduce((obj, i) => _.set(obj, [i['id_role']], i['role_name']), {}) : {},
  },
  {
    title: intl.formatMessage({ id: "superior" }),
    field: "org_chart.id_chief",
    lookup: users ? Object.values(users).reduce((obj, i) => _.set(obj, [i['id']], i['full_name']), {}) : {},
  },
  {
    title: intl.formatMessage({ id: "disabled" }),
    field: "disabled",
    type: "boolean",
  },
  {
    title: "Data ingresso",
    field: "org_chart.dat_ingresso",
    type: "date",
    render: rowData => <>{moment(rowData.org_chart.dat_ingresso).format("DD/MM/YYYY")}</>,
    editComponent: (props) => <CustomEditDatePicker {...props}/>,
    filterComponent: (props) => <CustomFilterDatePicker {...props}/>,
  },
  {
    title: "Data uscita",
    field: "org_chart.dat_uscita",
    type: "date",
    render: rowData => <>{rowData.org_chart.dat_uscita ? moment(rowData.org_chart.dat_uscita).format("DD/MM/YYYY") : ''}</>,
    editComponent: (props) => <CustomEditDatePicker {...props}/>,
    filterComponent: (props) => <CustomFilterDatePicker {...props}/>,
  },
  {
    title: "Data nascita",
    field: "org_chart.dat_nascita",
    type: "date",
    render: rowData => <>{moment(rowData.org_chart.dat_nascita).format("DD/MM/YYYY")}</>,
    editComponent: (props) => <CustomEditDatePicker {...props}/>,
    filterComponent: (props) => <CustomFilterDatePicker {...props}/>,
  },
  {
    title: "Ore giornaliere",
    field: "org_chart.num_ore_giornaliere"
  },
  {
    title: "Codice Gescli",
    field: "org_chart.cod_gescli"
  }
];

export const OPTIONS = {
  filtering: true,
  pageSize: 10,
  searchFieldAlignment: "left",
  headerStyle: {fontWeight: "bold"},
  searchFieldStyle: {marginTop: "2px"},
  showTitle: false,
  toolbar: false,
};

export const getLocalization = (intl) => ({
  header: {
    actions: "",
  },
  body: {
    editRow: {
      deleteText: intl.formatMessage({ id: "delete_user" }),
    },
  },
  pagination: {
    labelDisplayedRows: intl.formatMessage({ id: "users_displayed_rows" }),
    labelRowsSelect: intl.formatMessage({ id: "users"}),
  },
});
