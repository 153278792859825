import React, {useState, useEffect} from "react";
import {useIntl} from "react-intl";
// UI
import MaterialTable from "@material-table/core";
import _ from "lodash";

// Table Params
import {getColumns, OPTIONS, getLocalization} from "./Params";
import {tableIcons} from "../../TableIcons";
import AuthenticationService from "../../../services/AuthenticationService";
import {api} from "@services/apiRequest";
import TableStyle from "../../TableStyle";
import useOrganizationChart from "./hooks/useOrganizationChart";
import useAlmagammaRoles from "./hooks/useAlmagammaRoles";
import useRoles from "./hooks/useRoles";

function UsersTable({users, setUsers, push}) {
  const intl = useIntl();
  const roles = useRoles();
  const almagammaRoles = useAlmagammaRoles();
  const organizationChart = useOrganizationChart();
  const [columns, setColumns] = useState(getColumns(intl))
  const [localization] = useState(getLocalization(intl))

  const scopes = AuthenticationService.getUserScopes();
  const [data, setData] = useState();


  function manageUpdatedUser(oldData, newData) {
    const { [oldData.id]: _, ...rest } = users;
    const updatedUser =
      newData.role_id !== newData.role.id
        ? {
            ...newData,
            role: roles.find((r) => r.id === parseInt(newData.role_id)),
          }
        : newData;

    setUsers({ ...rest, [newData.id]: updatedUser });
  }

  useEffect(() => {
    if (users && roles && almagammaRoles) {
      setColumns(getColumns(intl, roles, almagammaRoles, users))
    }
  }, [intl, users, roles, almagammaRoles])


  useEffect(() => {
    if (users && organizationChart && almagammaRoles) {
      let _users = Object.values(users);
      _users.forEach((user) => {
        if (!user.org_chart && !user.almagamma_role) {
          const orgchart = organizationChart.find((org) => (org.id_user === user.id))
          const role = (orgchart) ? almagammaRoles.find((role) => (role['id_role'] === orgchart.id_user_role)) : undefined
          user.org_chart = (orgchart) ? orgchart : undefined
          user.almagamma_role = (role) ? role : undefined
        }
      });
      setData(_users);
    }
  }, [users, setData, almagammaRoles, organizationChart])

  const _onRowUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      if (newData.email) {
        newData.email = newData.email.trim().toLowerCase();
      }
  
      let isEqual = true;
      Object.entries(newData).forEach(([key, value]) => {
        if (oldData[key] !== value) {
          isEqual = false;
        }
      });
      if (isEqual) {
        resolve();
      } else {
        const newChiefId = parseInt(newData.org_chart['id_chief']);
        console.log(newChiefId);
        api
          .post(
            `roles-almagamma/organization-chart/${oldData.id}/${oldData.almagamma_role['id_role']}`,
            {
              id_user: oldData.id,
              id_user_role: parseInt(newData.almagamma_role['id_role']),
              id_chief: newChiefId,
              id_chief_role: parseInt(users[newChiefId].almagamma_role['id_role']),
              dat_ingresso: newData.org_chart.dat_ingresso,
              dat_uscita: newData.org_chart.dat_uscita,
              dat_nascita: newData.org_chart.dat_nascita,
              num_ore_giornaliere: newData.org_chart.num_ore_giornaliere,
              cod_gescli: newData.org_chart.cod_gescli,
            }
          )
          .then(() => {
            api
              .post(`/users/${oldData.id}`, newData)
              .then(() => {
                manageUpdatedUser(oldData, newData);
                push({
                  title: intl.formatMessage({
                    id: "user_updated_successfully",
                  }),
                  type: "success",
                });
                resolve();
              })
              .catch((error) => {
                console.error("Error during user update!", error);
                if (error?.response?.data?.detail === "email_already_in_use") {
                  push({
                    title: intl.formatMessage({
                      id: "email_already_in_use",
                    }),
                    type: "error",
                  });
                } else {
                  push({
                    title: intl.formatMessage({
                      id: "server_error",
                    }),
                    type: "error",
                  });
                }
                reject(new Error("Server error"));
              });
          });
      }
    });

  const _onRowDelete = (newData) =>
    new Promise((resolve, reject) => {
      api
        .delete(`/roles-almagamma/organization-chart/${newData.id}/${newData.org_chart.id_user_role}`)
        .then(() => {
          api
            .delete(`/users/${encodeURIComponent(newData.id)}`)
            .then(() => {
              const {[newData.id]: tmp, ...rest} = users;
              setUsers({...rest});
              push({
                title: intl.formatMessage({
                  id: "user_deleted_successfully",
                }),
                type: "success",
              });
              resolve();
            })
            .catch((err) => {
              console.error("Error during user delete!", err);
              push({
                title: intl.formatMessage({
                  id: "server_error",
                }),
                type: "error",
              });
              reject();
            });
        })
        .catch((err) => {
          console.error("Error during user delete!", err);
          push({
            title: intl.formatMessage({
              id: "server_error",
            }),
            type: "error",
          });
          reject(new Error());
        });
    });

  return (
    <TableStyle>
      <MaterialTable
        columns={columns}
        data={data} /* !STANDARD */
        icons={tableIcons}
        isLoading={data === undefined} /* !STANDARD */
        options={OPTIONS}
        localization={localization}
        editable={{
          ...(scopes.indexOf("user:update") >= 0
            ? {onRowUpdate: _onRowUpdate}
            : {}),
          ...(scopes.indexOf("user:delete") >= 0
            ? {onRowDelete: _onRowDelete}
            : {}),
        }}
      />
    </TableStyle>
  );
}

export default React.memo(UsersTable, (props, nextProps) => {
  // Render component only when users change
  return _.isEqual(props.users, nextProps.users);
});
